export default $axios => ({
    // 获取首页组件
    getCompo(params) {
        // return $axios.get(`/mall/module/homepage/v1/homepage/getData`, {
        return $axios.get(`/mall/module/homepage/v2/homepage/getData`, {
            params,
            config: {
                isSeo:true
            }
        });
    },
    // 获取推荐
    getRec(params) {
        return $axios.get(`/mall/module/homepage/v1/homepage/pageGoodsData`, {
            params,
            config: {
                hasLoading: true
            }
        });
    },
    // 获取首页seo
    getSeo(params) {
        return $axios.get(`/mall/v1/seo/optimize/searchOptimize`,{
            params,
            config: {
                isSeo:true
            }
        });
    },
    findCategoryFirstLetter(params){
        return $axios.get(`/mall/v1/category/findCategoryFirstLetter`,{
            params,
            config: {
            }
        });
    },
    categoryFirstLetterQuery(params){
        return $axios.get(`/mall/v1/category/categoryFirstLetterQuery`,{
            params,
            config: {
            }
        });
    },
    initialLetters(params){
        return $axios.get(`/mall/v3/spu/initialLetters`,{
            params,
            config: {
            }
        });
    },
    firstLetterQuery(params){
        return $axios.get(`/mall/v3/spu/firstLetterQuery`,{
            params,
            config: {
            }
        });
    }
  });